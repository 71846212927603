import React from 'react';
import Layout from '../components/layout';
import TalkToYouSoon from '../components/talk_to_you_soon';
import { Link } from 'gatsby';

const Thanks = () => (
  <Layout>
    <div className="content_wrap">
      <div className="container-fluid">
        <TalkToYouSoon baseLayer="img-fluid success_img my-3" />
        <div className="row">
          <div className="col-12">
            <div className="text-center py-3">
              <Link className="post_link" to="/">
                Submission Receieved, Go Back Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Thanks;
